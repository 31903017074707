import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {blogPosts: [],
  test:'hallo'},
  mutations: {
    setBlogPosts(state, list) {
      state.blogPosts = list;
    }
  },
  actions: {
    async getBlogs({commit}) {
      let files = await require.context('../assets/content/blog/', false, /\.json$/);
      console.log(files);
      let blogPosts = files.keys().map(key => {
        let res = files(key);
        res.slug = key.slice(2, -5);
        return res;
      });
      await commit('setBlogPosts', blogPosts);
    }
  },
  modules: {}
});
