<template>
    <div  v-scroll-to="'#we'">
        <div class="tablet-control">
           <!--<section class="hero is-fullheight-with-navbar video hero-video">
                <div class="hero-video">
                    <video muted autoplay playsinline>
                        <source src="../../public/img/TabletHeroV3.mp4" type="video/mp4">
                    </video>
                </div>
            </section> -->
            <!--<section class="hero  is-fullheight-with-navbar video individual_background">
                <figure class="is-flex-mobile hero-video">
                    <img src="../../public/img/DesktopheroPuplePicV2.png">
                </figure>
            </section> -->
        </div>
        <div class="is-hidden-mobile">
            <section class="hero  is-fullheight-with-navbar video individual_background">  
               <div class="is-flex-mobile hero-video is-transparent-hero"> 
                    <video muted autoplay>
                        <source src="../../public/img/ConstructionHeroNormalQuality.mp4" type="video/mp4">
                    </video>  
                </div>
            </section>

        </div>
        <div class="mobile-control">
            <section class="hero  is-fullheight-with-navbar video individual_background">  
               <div class="is-flex-mobile hero-video is-transparent-hero"> 
                    <video muted autoplay>
                        <source src="../../public/img/MobileConstruction.mp4" type="video/mp4">
                    </video>  
                </div>
            </section>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Herovideo"
    }

</script>

<style scoped>

.mobile-control {
    display: none;
}

.tablet-control {
    display: none;
}

.hd-control {
    display: none;
}

@media (max-width: 768px) {
  .mobile-control {
    display: contents;
  }
}

@media (min-width: 769px) and (max-width: 1407px) {
    .tablet-control {
        display: contents;
}
}

@media (min-width: 1408px) {
    .hd-control {
        display: contents;
        position: relative;
}
}


/*.hero-video { 
        position: relative;
        object-fit: cover; 
    } */
    #rightbtn{
        height: 50px;
        width: 50px;
    }
    #transparentbtn{
        background-color: transparent;
        border-color: transparent;
        height: 300px;
        width: 150px;
    }

    .arrowbtn{
        color: #ffffff;
    }
    .centerRadio {
        margin: auto;
        width: 40%;

    }
    /* origninal green: #b8f4d7 */
    #text {
        color: #8af2c0;
    }

    #text2 {
        color: #ffffff;
    }


    #customTop{
        margin-top: 50px;
    }
    #radioButton {
        margin-left: 10px;
    }
    /* Customize the label for desktop view (the container) */
    .container {

        display: block;
        position: relative;
        margin-bottom: 12px;
        pointer-events: none;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Customize the label for mobile view (the container) */
    .container_mob {

        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        pointer-events: none;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: none;
        height: 0;
        width: 0;

    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 10px;
        width: 10px;
        background-color: #8af2c0;
        border-radius: 50%;
    }



    /* When the radio button is checked, add a green background */
    .container input:checked ~ .checkmark {
        background-color: #8af2c0;

    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
        top: 2px;
        left: 2px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #000000;
        opacity: 0.5;
    }

    /* background color for hero video
    .individual_background {
        background-color: #000000
    } */
    /* add manipulation to bulma css classes to ensure o.k. positioning of hero*/
    .hero-video video { 
        position: relative;
        object-fit: cover; 
    } 
    /* add manipulation to bulma css classes to ensure o.k. positioning of hero*/
    .hero {
        position: relative;
    }


</style>
