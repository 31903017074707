import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import 'buefy/dist/buefy.css'
import VueSimpleMarkdown from 'vue-simple-markdown'
import 'vue-simple-markdown/dist/vue-simple-markdown.css'
import VueI18n from 'vue-i18n'
import { languages, defaultLocale } from './i18n/index.js';
import Vue2TouchEvents from 'vue2-touch-events'
import AOS from 'aos'
import 'aos/dist/aos.css'
Vue.config.productionTip = false;
Vue.use(Buefy);
Vue.use(VueSimpleMarkdown)
Vue.use(VueI18n)
Vue.use(Vue2TouchEvents)
var VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -50,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

const messages = Object.assign(languages)
const i18n = new VueI18n({
  locale: defaultLocale,
  messages
})


new Vue({
  created () {
    AOS.init()
  },
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
