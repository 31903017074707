<template>
  <div class="is-container">
    <div id="centered">
     <!-- <h1 class="subtitle is-3 is-size-4-mobile"><strong style="background-color: #b8f4d7">{{$t("ourSkillsTitle")}}</strong></h1> -->
    </div>
    <div class="columns is-centered"> 
      <div class="column is-2">
        <div class="card is-shadowless" id="body">
          <div class="card-image has-text-centered">
            <figure class="image is-96x96 is-inline-block">
              <img class="background" src="/img/ML.png">
            </figure>
          </div>
          <div class="card-content has-text-centered">
            <h1 class="title is-6 has-text-centered">Data Science & Machine Learning</h1>
        </div>
      </div>
    </div>

    <div class="column is-2">
      <div class="card is-shadowless" id="body">
        <div class="card-image has-text-centered">
          <figure class="image is-96x96 is-inline-block">
            <img class="" src="/img/Web.png">
          </figure>
        </div>
        <div class="card-content has-text-centered">
          <h1 class="title is-6 has-text-centered">Web Development</h1>
        </div>
      </div>
    </div>
    <div class="column is-2"> 
      <div class="card is-shadowless" id="body">
        <div class="card-image has-text-centered">
          <figure class="image is-96x96 is-inline-block">
            <img class="" src="/img/PO.png">
          </figure>
        </div>
        <div class="card-content has-text-centered">
          <h1 class="title is-6 has-text-centered">Product Leadership</h1>
        </div>
      </div>
    </div>
    <div class="column is-2">
      <div class="card is-shadowless" id="body">
        <div class="card-image has-text-centered">
          <figure class="image is-96x96 is-inline-block">
            <img class="" src="/img/Agile.png">
          </figure>
        </div>
        <div class="card-content has-text-centered">
          <h1 class="title is-6 has-text-centered">Agile Software Development</h1>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "Skills"
}
</script>

<style scoped>


</style>
