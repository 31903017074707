<template>
    <div>
        <b-navbar fixed-top >
            <template slot="brand">
                <b-navbar-item v-scroll-to="'#hero'">
                    <img
                         src="../assets/TechupAndIcon.png"
                         alt="More than software"
                    >
                </b-navbar-item>
            </template>
            <template slot="start">
                <b-navbar-item v-scroll-to="'#we'">
                   <strong> {{$t("navBarCategory1")}} </strong>
                </b-navbar-item>
                <b-navbar-item v-scroll-to="'#offer'" >
                  <strong>{{$t("navBarCategory2")}}</strong>
                </b-navbar-item>
                <b-navbar-item v-scroll-to="'#approach'">
                   <strong>{{$t("aboutTitle")}}</strong>
                </b-navbar-item>
                <!--<b-navbar-item v-scroll-to="'#dna'">
                  <strong>{{$t("companyh2")}}</strong>
                </b-navbar-item> -->
                <b-navbar-item tag="div">
                    <div class="buttons margin">

                        <div class=" button is-primary" v-scroll-to="'#contact'" >
                            <strong>{{$t("contactBtnLabel")}}</strong>
                        </div>
                    </div>
                </b-navbar-item>
            </template>

            <template slot="end">

              <b-navbar-item>
                <section class="is-hidden-mobile">
                  <div class="select is-primary is-small">
                    <select style="font-weight: bold" v-model='$i18n.locale'>
                      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
                     </select>
                  </div>
                </section>
                <section class="is-hidden-tablet">
                  <b-field >
                    <button style="font-weight: bold" class="button is-primary is-inverted" v-on:click="$i18n.locale='DE'">DE</button>
                    <button style="font-weight: bold" class="button is-primary is-inverted" v-on:click="$i18n.locale='EN'">EN</button>
                  </b-field>

                </section>
              </b-navbar-item>
            </template>
        </b-navbar>
      <!--<Carousel id="carousel"/> -->
      <Hero id="hero"/>
      <We id="we" ref="we"/>
      <Tabs id="offer"/>
      <Approach id="approach" style="padding-top: 60px;"/>
      <Us id="dna" style="padding-top: 0px;"/>
      
      <!--<Offer id="offer" data-aos="zoom-in-right"/> -->

      <ContactForm2 data-aos="flip-up" id="contact"></ContactForm2>
      <Footer @go-section="goToSection"></Footer>
    </div>
</template>

<script>
import Hero from "../components/HeroVideo.vue";
// import Carousel from "../components/FrontCarousel";
import Us from "../views/whyKtng"
// import Offer from "../views/Projects"
import We from "../views/About"
import Approach from "../views/Approach"
import ContactForm2 from "../components/ContactForm2";
import Tabs from "../views/Tabs";
import Footer from "../components/Footer";
    export default {
        name: "Navigation",
      components: {
        ContactForm2,
        Hero,
        // Carousel,
        Tabs,
        Us,
        We,
        // Offer,
        Approach,
        Footer
      },
      data () {
        return { 
          langs: ['EN','DE'],
       }
      },
      methods: {
          scrollToTop() {
              window.scrollTo(0,0);
          },
        goto(refName) {
          var element = this.$refs[refName];
          var top = element.offsetTop;
          window.scrollTo(0, top);
        },
        goToSection(value) {
          console.log(value)
          document.getElementById(value).scrollIntoView(
            {
              behavior: 'smooth'
            }
          )
        }
      }
    }
</script>

<style scoped>
    .margin {
        margin-right: 30px;
    }

  nav.navbar.is-fixed-top {
    opacity: 1;
    box-shadow: 0px 5px 9px 0px #7E7F7E;
  } 

</style>
