<template>
  <div class="container is-fluid" id="padding">

    <h1 class="subtitle is-4 is-size-4-mobile has-text-centered"><strong
        id="color">{{ headerPurple }}</strong></h1>
    <h1 class="title is-1 is-size-3-mobile has-text-centered">{{ header }}</h1>
    <h1 class="subtitle is-3 is-size-5-mobile has-text-centered">{{ headerText }}</h1>

    <div v-if="list!=null">
      <div class="columns is-mobile" v-for="(item,index) in list" :key="index">
        <div class=" column is-narrow">
          <i class="far fa-check-square" style="color:#8c67ef"></i>
        </div>
        <div class="column">
          {{ item.listItem }}
        </div>
      </div>
      <div class="column is-narrow">
        {{ content }}
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "titlehader",
  props: ['headerPurple', 'header', 'headerText', 'content', 'list'],
}
</script>

<style scoped>
#padding {
  padding-top: 50px;
}

#color {
  color: #8c67ef;
}

.test {
  background-color: #b8f4d7;
}


</style>
