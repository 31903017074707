<template>
    <div class="container">
    <div class="columns">
        <div class="column">
            <div class="card is-shadowless" id="body">
                <div class="card-content">
                    <div class="media">
                        <div class="media-left">
                            <figure class="image is-96x96">
                                <img src="../../public/img/Individual.png" alt="Placeholder image">
                            </figure>
                        </div>
                        <div class="media-content">
                            <p class="title is-4-desktop is-5-mobile">{{$t("aboutCard.C1.Title")}}</p>
                            <p class="subtitle is-6">{{$t("aboutCard.C1.Text")}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="card is-shadowless" id="body">
                <div class="card-content">
                    <div class="media">
                        <div class="media-left">
                            <figure class="image is-96x96">
                                <img src="../../public/img/Flex.png" alt="Placeholder image">
                            </figure>
                        </div>
                        <div class="media-content">
                            <p class="title is-4-desktop is-5-mobile">{{$t("aboutCard.C2.Title")}}</p>
                            <p class="subtitle is-6">{{$t("aboutCard.C2.Text")}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="columns">
        <div class="column">
            <div class="card is-shadowless" id="body">
                <div class="card-content">
                    <div class="media">
                        <div class="media-left">
                            <figure class="image is-96x96">
                                <img src="../../public/img/Partner.png" alt="Placeholder image">
                            </figure>
                        </div>
                        <div class="media-content">
                            <p class="title is-4-desktop is-5-mobile">{{$t("aboutCard.C3.Title")}}</p>
                            <p class="subtitle is-6">{{$t("aboutCard.C3.Text")}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="card is-shadowless" id="body">
                <div class="card-content">
                    <div class="media">
                        <div class="media-left">
                            <figure class="image is-96x96">
                                <img src="../../public/img/Idea.png" alt="Placeholder image">
                            </figure>
                        </div>
                        <div class="media-content">
                            <p class="title is-4-desktop is-5-mobile">{{$t("aboutCard.C4.Title")}}</p>
                            <p class="subtitle is-6">{{$t("aboutCard.C4.Text")}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>
<script>
    export default {
        name: 'AboutCards'
    }
</script>
<style scoped>
    #body{
        background-color: transparent;
    }

</style>
