<template>
    <div class="columns">

        <div class="column has-text-centered">
            <div class="content">
                <figure>
                    <img class="image is-96x96" src="../../public/img/Individual.png">
                </figure>
            </div>
            <div class="content">
                <p class="title" style="font-size: 1.5rem;">{{$t("aboutCard.C1.Title")}}</p>
            </div>
            <div class="content">
                <p class="subtitle is-6-mobile">{{$t("aboutCard.C1.Text")}}</p>
            </div>
        </div>

            <div class="column has-text-centered">
                <div class="content">
                    <figure >
                        <img class="image is-96x96" src="../../public/img/Flex.png">
                    </figure>
                </div>
                <div class="content">
                    <p class="title" style="font-size: 1.5rem;">{{$t("aboutCard.C2.Title")}}</p>
                </div>
                <div class="content">
                    <p class="subtitle is-6-mobile">{{$t("aboutCard.C2.Text")}}</p>
                </div>
            </div>

                <div class="column has-text-centered">
                    <div class="content">
                        <figure >
                            <img class="image is-96x96" src="../../public/img/Partner.png">
                        </figure>
                    </div>
                    <div class="content">
                        <p class="title" style="font-size: 1.5rem;">{{$t("aboutCard.C3.Title")}}</p>
                    </div>
                    <div class="content">
                        <p class="subtitle is-6-mobile">{{$t("aboutCard.C3.Text")}}</p>
                    </div>
                </div>

                    <div class="column has-text-centered">
                        <div class="content">
                            <figure >
                                <img class="image is-96x96" src="../../public/img/Idea.png">
                            </figure>
                        </div>
                        <div class="content">
                            <p class="title" style="font-size: 1.5rem;">{{$t("aboutCard.C4.Title")}}</p>
                        </div>
                        <div class="content">
                            <p class="subtitle is-6-mobile">{{$t("aboutCard.C4.Text")}}</p>
                        </div>
                    </div>
                </div>


</template>
<script>
    export default {
        name: 'AboutCardsMob'
    }
</script>
<style scoped>
    #body {
        background-color: transparent;
    }

</style>
