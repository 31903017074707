
<template>
    <div id="footer">
        <footer class="footer">
            <!-- AGB Modal start -->
            <div class="modal" v-bind:class="{'is-active': display}">
            <div class="modal-background"></div>
            <div class="modal-content">
            <!-- AGB Modal content -->
            <Agb></Agb>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="hideModal"></button>
            </div>
            <!-- AGB Modal end -->
            <div class="container">
                <div class="columns">
                    <div class="column footercontent">
                        <figure class="image" style="max-width: 200px;">
                            <a @click.prevent="goSection('hero')">
                                <img src="../../public/img/TechupLogoComplete.png">
                            </a> 
                        </figure>
                    </div>
                    <div class="column footercontent">    
                        <div class="title is-6" style="font-weight: bold;">{{$t("footerLegalNoticeTitle")}}</div>
                        <div>
                            TechUp Systems GmbH<br>
                            CHE-152.129.392<br>
                            <a href = "mailto: info@techup-systems.ch">info@techup-systems.ch</a>
                        </div>
                    </div>
                    <div class="column footercontent">
                        <div class="title is-6">{{$t("footerContentTitle")}}</div>
                        <a @click.prevent="goSection('we')">{{$t("navBarCategory1")}}</a><br>
                        <a @click.prevent="goSection('offer')">{{$t("navBarCategory2")}}</a><br>
                        <a @click.prevent="goSection('approach')">{{$t("aboutTitle")}}</a><br>
                        <a @click.prevent="goSection('contact')">{{$t("contactBtnLabel")}}</a><br>
                        <a @click="initModal()">{{$t("agbTitle")}}</a>
                    </div>
                    <div class="column footercontent">
                        <div class="title is-6">{{$t("footerFollowUsTitle")}}</div> <!-- https://www.linkedin.com/company/techup-systems -->
                        <a href="https://www.linkedin.com/company/techup-systems">
                            <figure class="image" style="max-width: 32px;">
                                <img src="../../public/img/linkedin_social_icon.png">
                            </figure>
                        </a>
                    </div>
                </div>
                <div class="has-text-centered" style="padding-top: 10px;">
                    Made with
                    <b-icon
                            pack="fas"
                            icon="heart"
                            >
                    </b-icon>
                    and Vue.js by
                    <span>TechUp Systems GmbH</span>
                </div>
                <div class="content">{{getYear()}}@TechUpSystems</div>

            </div>    
        </footer>
    </div>

</template>

<script>
import Agb from "../components/Agb.vue" 

    export default {
        name: "Footer",
        data () {
            return {
                display: false
            }
        },
        methods: {
            getYear () {
                const d = new Date();
                return d.getFullYear()
            },
            initModal () {
                this.display = true
            },
            hideModal () {
                this.display = false
            },
            goSection(val) {
                this.$emit("go-section", val)
            }
        },
        components: {
            Agb
        }
    }
</script>

<style scoped>
    #footer {
        text-align: center;
        margin-top: 50px;
    }

    .purple {
        color: #8c67ef;
        font-weight: bold;
    }
    .footercontent {
        text-align: left;
    }

</style>
