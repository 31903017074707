<template>

<div id="body" >
  <div class="is-fluid">
    <div id="centered">
      <h1 class="subtitle is-4 is-size-4-mobile"> <strong id="color2">{{$t("weh1Color")}}</strong></h1>
      <h1 class="title is-1 is-size-3-mobile">{{$t("weh2")}}</h1>
      <!-- <h1 class="subtitle is-3 is-size-5-mobile">{{$t("weh3")}}</h1> -->
    </div>
    <Staff></Staff>
    <!-- <Skills/> -->
    <Area/>
  </div>
</div>
</template>

<script>
  import Staff from "../components/Staff";
  // import Skills from "../components/Skills";
  import Area from "../components/Area";

  export default {
    name: "About",
    components: {

      Staff,
      // Skills,
      Area

    }
  };
</script>
<style>
  #body {

    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

  }


  #color2{
    color: #8c67ef;
  }
  #centered{
    text-align: center;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  #purple
  {
    color: #8c67ef;
    text-align: center;
  }

</style>
