import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/Navigation.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/Disciplines",
    name: "Disciplines",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Disciplinevi.vue")
  },
  {
    path: "/Agile",
    name: "Agile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Agileview.vue")
  },
  {
    path: "/Webdev",
    name: "Webdev",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Webdevview.vue")
  },
  {
    path: "/Testing",
    name: "Testing",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Testingview.vue")
  },
  {
  path: "/Contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/Contactview.vue")
},
  {
    path: "/ComingSoon",
    name: "ComingSoon",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/InProgressview.vue")
  },
  {
    path: "/Blog",
    name: "Blog",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Blog.vue")
  },
  {
    path: "/id=:title",
    name: "Blogdetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/BlogDetailView.vue")
  },
  {
    path: "/admin",
    name: "Admin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/admin.vue")
  },
  {
    path: "/contacted",
    name: "Contacted",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
        import(/* webpackChunkName: "about" */ "../views/contactConfirmation.vue")
  },
  {
    path: "/Projects",
    name: "Projects",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Projects.vue")
  },
  {
    path: "/Plans",
    name: "Plans",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Plans.vue")
  },
  {
    path: "/We",
    name: "We",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
        import(/* webpackChunkName: "about" */ "../views/whyKtng.vue")
  },
  {
    path: "/Approach",
    name: "Approach",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Approach.vue")
  },
  {
    path: "/Tabs",
    name: "Tabs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Tabs.vue")
  },
  {
    path: "*",
    name: "Home",
    component: Home
  },

];

const router = new VueRouter({
  mode:'history',
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
});

export default router;
