<template>
    <div class="is-container" id="columns">
    <div class="columns is-centered">
        <div class="column is-4">
            <div class="card is-shadowless" id="body">
                <div class="has-text-centered">
                    <figure class="image is-128x128 is-inline-block">
                        <img class="is-rounded" src="../assets/fabioII.png">
                    </figure>
                </div>
                <div class="card-content has-text-centered">
                    <h1 class="title is-5 has-text-centered" id="purple">Fabio</h1>
                    <h1 class="subtitle is-6 has-text-centered">{{$t("staff1")}}</h1>
                  <button class="button is-medium is-primary is-outlined">
              <span class="icon">
                <i class="fas fa fa-at"></i>
              </span>
                    <span v-scroll-to="'#contact'"><strong>{{$t("msgBtnLabel")}}</strong></span>
                  </button>
                    <div>
                    </div>
                </div>
            </div>
        </div>
      <div class="column is-4">
        <div class="card is-shadowless" id="body">
          <div class="has-text-centered">
            <figure class="image is-128x128 is-inline-block">
              <img class="is-rounded" src="../assets/filip_new_smallV2.png">
            </figure>
          </div>
          <div class="card-content has-text-centered">
            <h1 class="title is-5 has-text-centered" id="purple">Filip</h1>
            <h1 class="subtitle is-6 has-text-centered ">{{$t("staff2")}}</h1>
            <button class="button is-medium is-primary is-outlined">
              <span class="icon">
                <i class="fas fa fa-at"></i>
              </span>
              <span v-scroll-to="'#contact'"><strong>{{$t("msgBtnLabel")}}</strong></span>
            </button>
          </div>
        </div>
      </div>


    </div>
    </div>
</template>

<script>
    export default {
        name: "Staff"
    }
</script>

<style scoped>
    #body{
        background-color: transparent;
    }
    #margin{
        margin-top: 60px;
        text-align: center;
    }
    #columns{
        margin-top: 30px;
        padding-bottom: 10px;
    }
    #greenback{
      background-color: #b8f4d7
    }
    #purple{
      color: #8c67ef;
    }

</style>
