<template>
    <div class="background" style="margin-top: 48px;">
        <titleheader 
            v-bind:header="header"
            v-bind:header-purple="headerPurple"
            style=" margin-bottom: 48px;"      
        >
        </titleheader >


        <div class=" is-hidden-mobile" style="padding-top: 48px;">
          <div id="centered" class="container background">
            <figure  class="image">
                <img v-if="$i18n.locale==='DE'" src="/img/AnsatzDEV2.svg" alt="Placeholder image">
                <img  v-if="$i18n.locale==='EN'" src="/img/AnsatzENV2.svg" alt="Placeholder image">
            </figure>
            </div>
        </div>

        <div class="is-hidden-tablet has-text-centered" style="padding-bottom: 48px;">
          <figure  class="image" >
            <img v-if="$i18n.locale==='DE'" src="/img/AnsatzMobDe.png" alt="Placeholder image">
            <img  v-if="$i18n.locale==='EN'" src="/img/AnsatzMobEn.png" alt="Placeholder image">
          </figure>
        </div>
    </div>
</template>

<script>
    import titleheader from "../components/titleheader";
    export default {
        name: "Approach",
        components: {
            titleheader
        },
        computed: {
            header: function () { return this.$t('approachHeaderTitle')},
            headerPurple: function (){return this.$t('approachTitle')},
        }
    }

</script>

<style scoped>

.background {
    background-color: #c4dfe9;
}

</style>
