<template>
  <div data-aos="fade-right" style="background-color: #C4DFE9;">
    <div id="centered">
      <h1 class="subtitle is-3 is-size-4-mobile" ><strong>{{$t("ourArea")}}</strong></h1>
    </div>
    <div class="columns is-centered">
      <div class="column is-2">
        <div class="card is-shadowless" id="body">
          <div class="card-image has-text-centered">
            <figure class="image is-96x96 is-inline-block">
              <img class="" src="/img/Bank.png">
            </figure>
          </div>
          <div class="card-content has-text-centered">
            <h1 class="title is-6 has-text-centered">Finance & Insurance</h1>
          </div>
        </div>
      </div>

      <div class="column is-2">
        <div class="card is-shadowless" id="body">
          <div class="card-image has-text-centered">
            <figure class="image is-96x96 is-inline-block">
              <img class="" src="/img/Manufacturing.png">
            </figure>
          </div>
          <div class="card-content has-text-centered">
            <h1 class="title is-6 has-text-centered">Industrial Manufacturing</h1>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="card is-shadowless" id="body">
          <div class="card-image has-text-centered">
            <figure class="image is-96x96 is-inline-block">
              <img class="" src="/img/telecom.png">
            </figure>
          </div>
          <div class="card-content has-text-centered">
            <h1 class="title is-6 has-text-centered">Communication Technologies</h1>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="card is-shadowless" id="body">
          <div class="card-image has-text-centered">
            <figure class="image is-96x96 is-inline-block">
              <img class="" src="/img/Ecommerce.png">
            </figure>
          </div>
          <div class="card-content has-text-centered">
            <h1 class="title is-6 has-text-centered">E-Commerce</h1>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="card is-shadowless" id="body">
          <div class="card-image has-text-centered">
            <figure class="image is-96x96 is-inline-block">
              <img class="" src="/img/Media.png">
            </figure>
          </div>
          <div class="card-content has-text-centered">
            <h1 class="title is-6 has-text-centered">Media & Advertising</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Area"
}
</script>

<style scoped>
.card {
  background-color: #C4DFE9;
}
</style>
