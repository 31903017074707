<template>
    <section class="container">
        <div v-for="(section, index) of agbSections" :key="index">
            <b-collapse 
                class="card" 
                animation="slide" 
                aria-id="contentIdForA11y3"
                :open=false>
                <template #trigger="props">
                    <div
                        class="card-header"
                        role="button"
                        aria-controls="contentIdForA11y3"
                        :aria-expanded="props.open">
                        <p class="card-header-title textleft">
                            {{section.agbSectionTitle}}
                        </p>
                        <a class="card-header-icon">
                            <b-icon
                                :icon="props.open ? 'menu-down' : 'menu-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content">
                    <div class="content linebreak textleft">
                        {{section.agbSectionContent}}
                    </div>
                </div>
            </b-collapse>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Agb",
        computed: {
            agbSections: function () {
                return [
                    {
                        agbSectionTitle: this.$t('agbGeltungsbereichTitle'),
                        agbSectionContent: this.$t('agbGeltungsbereichContent')
                    },
                    {    
                        agbSectionTitle: this.$t('agbPflichtenUndVerantwortlichkeitenAgenturTitle'),
                        agbSectionContent: this.$t('agbPflichtenUndVerantwortlichkeitenAgenturContent'),
                    },
                    {
                        agbSectionTitle: this.$t('agbPflichtenDerKundinTitle'),
                        agbSectionContent: this.$t('agbPflichtenDerKundinContent'),
                    },
                    {
                        agbSectionTitle: this.$t('agbVergütungTitle'),
                        agbSectionContent: this.$t('agbVergütungContent'),
                    },
                    {
                        agbSectionTitle: this.$t('agbZusatzleistungenTitle'),
                        agbSectionContent: this.$t('agbZusatzleistungenContent'),
                    },
                    {
                        agbSectionTitle: this.$t('agbDatenUndUnterlagenTitle'),
                        agbSectionContent: this.$t('agbDatenUndUnterlagenContent'),
                    },
                    {
                        agbSectionTitle: this.$t('agbGeistigesEigentumTitle'),
                        agbSectionContent: this.$t('agbGeistigesEigentumContent'),
                    },
                    {
                        agbSectionTitle: this.$t('agbDiskretionTitle'),
                        agbSectionContent: this.$t('agbDiskretionContent'),
                    },
                    {
                        agbSectionTitle: this.$t('agbTermineTitle'),
                        agbSectionContent: this.$t('agbTermineContent'),
                    },
                    {
                        agbSectionTitle: this.$t('agbAbnahmeTitle'),
                        agbSectionContent: this.$t('agbAbnahmeContent'),
                    },
                    {
                        agbSectionTitle: this.$t('agbHaftungTitle'),
                        agbSectionContent: this.$t('agbHaftungContent'),
                    },
                    {
                        agbSectionTitle: this.$t('agbLeistungenDritterTitle'),
                        agbSectionContent: this.$t('agbLeistungenDritterContent'),
                    },
                    {
                        agbSectionTitle: this.$t('agbAbwerbeverbotTitle'),
                        agbSectionContent: this.$t('agbAbwerbeverbotContent'),
                    },
                    {
                        agbSectionTitle: this.$t('agbSchriftlichkeitTitle'),
                        agbSectionContent: this.$t('agbSchriftlichkeitContent'),
                    },
                    {
                        agbSectionTitle: this.$t('agbÜbertragungTitle'),
                        agbSectionContent: this.$t('agbÜbertragungContent'),
                    },
                    {
                        agbSectionTitle: this.$t('agbGerichtsstandTitle'),
                        agbSectionContent: this.$t('agbGerichtsstandContent'),
                    }
                ]
            }
        }
    }
</script>

<style scoped>
    .textleft {
        text-align: left;
    }
    .linebreak {
        white-space: pre-line;
    }
</style>