<template>
  <div class="container" style="padding-top: 50px; padding-left: 12px; padding-right: 12px;">
    <titleheader 
      v-bind:header="header"
      v-bind:content="content"
      v-bind:header-purple="headerPurple"
      
    >
    </titleheader >
    <Skills></Skills>
  <div style="padding-top: 60px;" data-aos="fade-left">
    <div class="subtitle is-3 is-size-5-mobile has-text-centered">{{$t('offerHeaderSubTitle')}}</div>
    <div class="columns is-centered" 
        v-for="(collapse, index) of collapses"
        :key="index" >
      <div class="column is-two-thirds" style="margin-top: 20px">
        <section>
            <div class="content">
                <h3>
                    <!-- icons: https://fontawesome.com/v5/websearch? -->
                    <i :class="collapse.icon" style="color:#8c67ef;margin-right: 5px"></i>
                    <span class="greenback">{{ collapse.headertitle }}</span>
                </h3>
                <p>
                    {{collapse.entrytext}}
                </p>
            </div>
            
            <b-collapse 
                :open= false
                position="is-bottom"
                @open="isOpen = index" 
                aria-id="contentIdForA11y4">
                <template #trigger="props">
                    <a
                        aria-controls="contentIdForA11y4"
                        :aria-expanded="props.open">
                        <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
                        {{ !props.open ? $t('moreExpander') : $t('lessReducer') }}
                    </a>
                </template>
                <div class="content">
                    <div class="column">
                    <strong class="greenback">{{collapse.title0}}</strong><p/>
                    {{collapse.text0}}
                    </div>
                    <div class="column">
                    <strong class="greenback">{{collapse.title1}}</strong><p/>
                    {{collapse.text1}}
                    </div>
                    <div class="column">
                    <strong class="greenback">{{collapse.title2}}</strong><p/>
                    {{collapse.text2}}
                    </div>
                </div>  
            </b-collapse>
        </section>
      </div>
    </div>
  </div> 
  </div>
</template>

<script>
import titleheader from "../components/titleheader";
import Skills from "../components/Skills";

export default {
  name: "Tabs",
  components: {
    titleheader,
    Skills
  },
  data: function () {
    return {


      content: this.contactText,
      headerText:
          "",
      isOpen: 0,


    };
  },
  computed:{
    header: function () { return this.$t('offerHeaderTitle')},
    headerPurple: function (){return this.$t('projectsTitle')},
    contactText: function (){return this.$t('offerHeaderSubTitle')},
    collapses: function () {
      return [
        {
          headertitle: this.$t('accHeader1'),
          entrytext: this.$t('accEntryText1'),
          title0: this.$t('accTitle1.0'),
          text0: this.$t('accText1.0'),
          title1: this.$t('accTitle1.1'),
          text1: this.$t('accText1.1'),
          title2: this.$t('accTitle1.2'),
          text2: this.$t('accText1.2'),
          icon: this.$t('accIcon1'),
          industries: "Banking, Insurance, Telecommunications, Retail, Healthcare"
        },
        {
          headertitle: this.$t('accHeader2'),
          entrytext: this.$t('accEntryText2'),
          title0: this.$t('accTitle2.0'),
          text0: this.$t('accText2.0'),
          title1: this.$t('accTitle2.1'),
          text1: this.$t('accText2.1'),
          title2: this.$t('accTitle2.2'),
          text2: this.$t('accText2.2'),
          icon: this.$t('accIcon2'),
          industries: 'Telecommunications & Retail'
        },
       /* {
          headertitle: this.$t('accHeader3'),
          entrytext: this.$t('accEntryText3'),
          title0: this.$t('accTitle3.0'),
          text0: this.$t('accText3.0'),
          title1: this.$t('accTitle3.1'),
          text1: this.$t('accText3.1'),
          title2: this.$t('accTitle3.2'),
          text2: this.$t('accText3.2'),
          icon: this.$t('accIcon3'),
          industries: 'Banking, Insurance, Telecommunications, Retail'
        }, */
        {
          headertitle: this.$t('accHeader4'),
          entrytext: this.$t('accEntryText4'),
          title0: this.$t('accTitle4.0'),
          text0: this.$t('accText4.0'),
          title1: this.$t('accTitle4.1'),
          text1: this.$t('accText4.1'),
          title2: this.$t('accTitle4.2'),
          text2: this.$t('accText4.2'),
          icon: this.$t('accIcon4'),
          industries: 'Banking, Telecommunications, Retail, Healthcare'
        }
      ]
    }
  }

};
</script>




<style scoped>
    .content {
        margin-bottom: 0 !important;
    }
    .greenback{
        background-color: #b9f6ca
    }
</style>