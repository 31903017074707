<template>
    <div style="padding-bottom: 48px; padding-top: 60px;">
    <div class="is-hidden-mobile">
    <section class="" style="padding-top: 80px">
        <div class="">
        <div id="centered" class="container">
             <!--<small id="purple" class="subtitle is-4 has-text-weight-bold">{{$t("companyh2")}}</small> -->
            <small class="subtitle is-4">{{$t("companyh3")}}</small>
        </div>
            <div class="">
            <AboutCards style="padding-top: 48px" data-aos="fade-right"/>
            </div>
        </div>
    </section>
    </div>
    <div class="is-hidden-tablet">
        <div>
            <section class="">
                <div class="has-text-centered">
                    <div style="padding-right: 12px; padding-left: 12px; padding-top: 40px;">
                        <!--<small id="purple" class="subtitle is-7-mobile has-text-weight-bold">{{$t("companyh2")}}</small> -->
                        <small class="subtitle is-7-mobile">{{$t("companyh3")}}</small>
                    </div>
                    <div class="">
                        <AboutCardsMob style="padding-left: 24px; padding-right: 24px; padding-top: 48px" data-aos="fade-left"/>
                    </div>
                </div>
            </section>
    </div>
    </div>
    </div>
</template>

<script>
    import AboutCards from "../components/AboutCards";
    import AboutCardsMob from "../components/AboutCardsMob";
    export default {
        name: "whyKtng",
        components: {

            AboutCards,
            AboutCardsMob
        }
    }
</script>

<style scoped>
    #body {

        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;

    }


    #color2{
        color: #8c67ef;
    }
    #centered{
        text-align: center;
        padding-bottom: 0px;
        padding-top: 0px;
    }
    #mobile{
        text-align: center;
        margin-left: 5px;
        margin-right: 5px;
    }
    #purple
    {
        color: #8c67ef;
        text-align: center;
    }
</style>
